class SearchAdvancedForm {
    static start() {
        window.addEventListener('click', e => {
            let advancedSearchForm = document.getElementById('search-advanced-form');
            if (!advancedSearchForm) {
                return
            }

            let addButton = e.target.closest('.search-advanced-form-row-add-button');
            if (addButton) {
                let row = addButton.closest('.search-advanced-form-row')
                if (!row) {
                    return
                }

                let rowNum = row.dataset['evRow']
                if (typeof rowNum !== 'string') {
                    return
                }

                let rowNumInt = Math.floor(Number(rowNum))
                if (isNaN(rowNumInt)) {
                    return
                }

                let buttonsCol = row.getElementsByClassName('search-advanced-form-row-buttons-col')[0]
                if (buttonsCol) {
                    buttonsCol.classList.add('d-none')
                }
                let opCol = row.getElementsByClassName('search-advanced-form-row-op-col')[0]
                if (opCol) {
                    opCol.classList.remove('d-none')
                    let opSelect = opCol.getElementsByTagName('select')[0]
                    if (opSelect) {
                        opSelect.disabled = false
                        opSelect.selectedIndex = 0
                    }
                }

                let nextRowNumInt = rowNumInt + 1
                let nextRow = row.cloneNode(true)
                nextRow.dataset['evRow'] = nextRowNumInt

                // Only present for the first row
                let nextHiddenTerm = nextRow.getElementsByClassName('search-advanced-form-row-hidden-term')[0]
                if (nextHiddenTerm) {
                    nextHiddenTerm.remove()
                }

                let nextFieldNameSelect = (nextRow.getElementsByClassName('search-advanced-form-row-field-name-col')[0])?.getElementsByTagName('select')[0]
                if (nextFieldNameSelect) {
                    nextFieldNameSelect.name = `expr[${nextRowNumInt}][name]`
                    nextFieldNameSelect.selectedIndex = 0
                }

                let nextTermInput = (nextRow.getElementsByClassName('search-advanced-form-row-term-col')[0])?.getElementsByTagName('input')[0]
                if (nextTermInput) {
                    nextTermInput.name = `expr[${nextRowNumInt}][term]`
                    nextTermInput.value = ''
                }

                let nextOpCol = nextRow.getElementsByClassName('search-advanced-form-row-op-col')[0]
                if (nextOpCol) {
                    nextOpCol.classList.add('d-none')
                    let nextOpSelect = nextOpCol.getElementsByTagName('select')[0]
                    if (nextOpSelect) {
                        nextOpSelect.name = `expr[${nextRowNumInt + 1}][op]`
                        nextOpSelect.disabled = true
                        nextOpSelect.selectedIndex = 0
                    }
                }

                let nextButtonsCol = nextRow.getElementsByClassName('search-advanced-form-row-buttons-col')[0]
                if (nextButtonsCol) {
                    nextButtonsCol.classList.remove('d-none')
                }

                let nextRemoveButton = nextRow.getElementsByClassName('search-advanced-form-row-remove-button')[0]
                if (nextRemoveButton) {
                    nextRemoveButton.classList.remove('d-none')
                }

                row.parentElement.appendChild(nextRow)


                let form = row.closest('form')
                if (form) {
                    let submit = form.querySelector('button[type="submit"]')
                    if (submit) {
                        submit.click()
                    }
                }

                return
            }

            let removeButton = e.target.closest('.search-advanced-form-row-remove-button');
            if (removeButton) {
                let row = removeButton.closest('.search-advanced-form-row')
                if (!row) {
                    return
                }

                let form = row.closest('form')

                let rowNum = row.dataset['evRow']
                if (typeof rowNum !== 'string') {
                    return
                }

                let rowNumInt = Math.floor(Number(rowNum))
                if (isNaN(rowNumInt)) {
                    return
                }

                let prevRowNumInt = rowNumInt - 1
                let prevRow = row.parentElement.querySelector(`.search-advanced-form-row[data-ev-row='${prevRowNumInt}']`)
                if (prevRow) {
                    let prevOpCol = prevRow.getElementsByClassName('search-advanced-form-row-op-col')[0]
                    if (prevOpCol) {
                        prevOpCol.classList.add('d-none')
                        let prevOpSelect = prevOpCol.getElementsByTagName('select')[0]
                        if (prevOpSelect) {
                            prevOpSelect.disabled = true
                        }
                    }

                    let prevButtonsCol = prevRow.getElementsByClassName('search-advanced-form-row-buttons-col')[0]
                    if (prevButtonsCol) {
                        prevButtonsCol.classList.remove('d-none')
                    }

                    let prevRemoveButton = prevRow.getElementsByClassName('search-advanced-form-row-remove-button')[0]
                    if (prevRemoveButton && prevRowNumInt === 0) {
                        prevRemoveButton.classList.add('d-none')
                    }
                }

                row.remove()

                if (form) {
                    let submit = form.querySelector('button[type="submit"]')
                    if (submit) {
                        submit.click()
                    }
                }
            }
        })
    }
}

export default SearchAdvancedForm