class AdminNewUpdate {
    static start() {
        document.addEventListener("turbolinks:load", () => {
            const updateFileElement = document.getElementById('update-file')
            if (updateFileElement) {
                import("@rails/activestorage").then(ActiveStorage => {
                    ActiveStorage.start()
                }).then(_ => {
                    updateFileElement.addEventListener('change', event => {
                        const { target } = event
                        target.parentElement.querySelectorAll(".invalid-feedback").forEach(element => element.remove())
                        target.classList.remove('is-invalid')
                    })
                    addEventListener("direct-upload:initialize", event => {
                        const { target, detail } = event
                        const { id } = detail
                        target.parentElement.querySelectorAll(".invalid-feedback").forEach(element => element.remove())
                        target.classList.remove('is-invalid')
                        target.insertAdjacentHTML("afterend",
                            `<div id="update-file-progress-${id}" class="progress mt-2">
                                <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style="width: 0%;" aria-valuenow="2.5" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>`)
                    })
                    addEventListener("direct-upload:progress", event => {
                        const { id, progress } = event.detail
                        const progressChunk = Math.max(2.5, Math.trunc(progress / 2.5) * 2.5)
                        const progressElement = document.getElementById(`update-file-progress-${id}`)
                        const progressBar = progressElement.firstElementChild
                        progressBar.style.width = `${progressChunk}%`
                        progressBar.setAttribute('aria-valuenow', progressChunk.toString())
                    })
                    addEventListener("direct-upload:end", event => {
                        const { id } = event.detail
                        const progressElement = document.getElementById(`update-file-progress-${id}`)
                        progressElement.remove()
                    })
                    addEventListener("direct-upload:error", event => {
                        event.preventDefault()
                        const { target, detail } = event
                        const { error } = detail
                        target.classList.add('is-invalid')
                        target.parentElement.insertAdjacentHTML("beforeend",
                            `<div class="invalid-feedback">Upload failed: ${error}</div>`)
                        target.form.querySelectorAll(".btn").forEach(btn => {
                            btn.removeAttribute('disabled')
                            const enableWith = btn.dataset.enableWith
                            if (enableWith && btn.tagName === 'INPUT') {
                                btn.setAttribute('value', enableWith)
                            }
                        })
                    })
                })
            }
        })
    }
}

export default AdminNewUpdate