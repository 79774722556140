class SearchOrder {
    static start() {
        // set up listeners and starting state based on current selections
        document.addEventListener("turbolinks:load", () => {
            let fieldSelectIds = ['search-order-field-select', 'mobile-search-order-field-select']
            for (const fieldSelectId of fieldSelectIds) {
                let fieldSelect = document.getElementById(fieldSelectId);
                if (!fieldSelect) {
                    continue
                }

                fieldSelect.addEventListener('change', e => {
                    let form = e.target.form;
                    if (form) {
                        form.submit()
                    }
                })
            }
        })
    }
}

export default SearchOrder