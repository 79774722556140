import TurbolinksHelper from "./turbolinks-helper";

class AutoReload {
    static start() {
        let blocks = 0;

        // auto-reloading of pages like catalog update pages
        document.addEventListener("turbolinks:load", () => {
            let refreshInterval = parseInt(document.querySelector('body').attributes.getNamedItem('data-ev-page-refresh-ms')?.value)
            if (!isNaN(refreshInterval) && refreshInterval > 0) {
                let saneRefreshInterval = Math.max(Math.min(refreshInterval, 1000), 10000)
                if (saneRefreshInterval > 0) {
                    TurbolinksHelper.setInterval(function () {
                        if (blocks === 0) {
                            window.location.reload();
                        }
                    }, saneRefreshInterval);
                }
            }
        })

        document.addEventListener("shown.bs.modal", () => {
            blocks++
        })

        document.addEventListener("shown.bs.dropdown", () => {
            blocks++
        })

        document.addEventListener("hidden.bs.modal", () => {
            blocks--
        })

        document.addEventListener("hidden.bs.dropdown", () => {
            blocks--
        })
    }
}

export default AutoReload