class Alerts {
    static start() {
        document.addEventListener("turbolinks:load", () => {
            const alertsChooseSelect = document.getElementById('alerts-choose-select')
            if (alertsChooseSelect) {
                // a change to the alert select will move to that alert page
                alertsChooseSelect.addEventListener('change', (e) => {
                    Turbolinks.visit(e.target.value)
                })
            }
        })
    }
}

export default Alerts