import "@popperjs/core"
import * as bootstrap from 'bootstrap'

class Tooltips {
    static start() {
        function startToggle(toggleName, toggleType) {
            [].slice.call(document.querySelectorAll(`[data-bs-toggle=${toggleName}]`)).map(el => {
                let showDelay = el.dataset.evShowDelay || 0
                let hideDelay = el.dataset.evHideDelay || 0

                let toggleComponent = new toggleType(el, {delay: {show: showDelay, hide: hideDelay}});
                el.addEventListener(`hide.bs.${toggleName}`, function (event) {
                    let toggleSelector = `.${toggleName}`
                    if (document.querySelectorAll(`${toggleSelector}:hover`).length) {
                        event.preventDefault()
                        document.querySelectorAll(toggleSelector).forEach(toggleEl => {
                            toggleEl.addEventListener('mouseleave', () => {
                                toggleComponent.hide()
                            })
                        })
                    }
                })
            });
        }

        function disposeToggle(toggleName, toggleType) {
            // Dispose of tooltips and popovers so they are hidden and removed from the DOM
            // and not visible (but with no behaviour attached) when page is loaded from the
            // Turbolinks cache

            [].slice.call(document.querySelectorAll(`[data-bs-toggle=${toggleName}]`)).map(el => {
                toggleType.getInstance(el)?.dispose()
            });
        }

        document.addEventListener("turbolinks:load", () => {
            startToggle("tooltip", bootstrap.Tooltip);
            startToggle("popover", bootstrap.Popover);
        })

        document.addEventListener('turbolinks:before-cache', () => {
            disposeToggle("tooltip", bootstrap.Tooltip);
            disposeToggle("popover", bootstrap.Popover);
        });
    }
}

export default Tooltips