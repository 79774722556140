import "@popperjs/core"

class Badges {
    static start() {
        document.addEventListener("turbolinks:load", () => {
            [].slice.call(document.querySelectorAll('.article-badges ul')).forEach(tabListEl => {
                let buttonEls = [].slice.call(tabListEl.querySelectorAll('li button'))

                let activate = (triggerEl) => {
                    triggerEl.classList.add('active')
                    let tabElQuery = triggerEl.getAttribute('data-bs-target')
                    if (tabElQuery) {
                        let tabEl = document.querySelector(tabElQuery)
                        if (tabEl) {
                            tabEl.classList.add('active')
                        }
                    }
                }

                let deactivateAll = () => {
                    buttonEls.forEach(el => {
                        el.classList.remove('active')
                        let tabElQuery = el.getAttribute('data-bs-target')
                        if (tabElQuery) {
                            let tabEl = document.querySelector(tabElQuery)
                            if (tabEl) {
                                tabEl.classList.remove('active')
                            }
                        }
                    })
                }

                buttonEls.forEach(el => {
                    el.addEventListener('click', event => {
                        event.preventDefault()

                        let makeActive = !el.classList.contains('active')

                        deactivateAll()

                        if (makeActive) {
                            activate(el)
                        }
                    })
                })
            });
        })
    }
}

export default Badges