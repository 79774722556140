class TurbolinksHelper {
    static setInterval(fn, delay) {
        let interval = setInterval(fn, delay);

        let beforeCacheListener = function () {
            clearInterval(interval);
            document.removeEventListener('turbolinks:before-cache', beforeCacheListener);
        };
        document.addEventListener('turbolinks:before-cache', beforeCacheListener);

        let beforeRenderListener = function () {
            clearInterval(interval);
            document.removeEventListener('turbolinks:before-render', beforeRenderListener);
        };
        document.addEventListener('turbolinks:before-render', beforeRenderListener);
    }

    static setTimeout(fn, delay) {
        let timer = setTimeout(fn, delay);

        let beforeCacheListener = function () {
            clearTimeout(timer);
            document.removeEventListener('turbolinks:before-cache', beforeCacheListener);
        };
        document.addEventListener('turbolinks:before-cache', beforeCacheListener);

        let beforeRenderListener = function () {
            clearTimeout(timer);
            document.removeEventListener('turbolinks:before-render', beforeRenderListener);
        };
        document.addEventListener('turbolinks:before-render', beforeRenderListener);
    }
}

export default TurbolinksHelper