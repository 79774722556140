class SearchPageSize {
    static start() {
        // set up listeners and starting state based on current selections
        document.addEventListener("turbolinks:load", () => {
            let selectIds = ['search-page-size-select', 'mobile-search-page-size-select']
            for (const selectId of selectIds) {
                let select = document.getElementById(selectId);
                if (!select) {
                    continue
                }

                select.addEventListener('change', e => {
                    let form = e.target.form;
                    if (form) {
                        form.submit()
                    }
                })
            }
        })
    }
}

export default SearchPageSize