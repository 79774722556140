import "@popperjs/core"
import Turbolinks from "turbolinks";
import FormHelper from "./form-helper";

class SearchFacets {
    static start() {
        window.addEventListener('submit', function (e) {
            if (e.target.id === 'pub-date-filter-form') {
                e.preventDefault()
                Turbolinks.visit(e.target.action + (e.target.action.indexOf('?') === -1 ? '?' : '&') + FormHelper.serialize(e.target, e.submitter));
            }
        })

        document.addEventListener('turbolinks:before-cache', () => {
            const searchFacetsEl = document.getElementById('search-facets')
            if (searchFacetsEl === null) {
                return
            }

            Array.from(searchFacetsEl.getElementsByTagName('ul')).forEach(ul => {
                Array.from(ul.getElementsByTagName('li')).forEach(li => {
                    if (li.dataset.evFacetElidable === 'true') {
                        li.classList.add('d-none')
                    }
                    ul.querySelector('button.view-all')?.closest('li')?.classList?.remove('d-none')
                    ul.querySelector('button.view-fewer')?.closest('li')?.classList?.add('d-none')

                    let checkbox = li.querySelector('input[type="checkbox"]')
                    if (checkbox) {
                        checkbox.checked = li.dataset.evFacetSelected === 'true'
                    }
                })
            })

            let start_year_field = document.getElementById('pub-date-filter-start-year')
            if (start_year_field) {
                    start_year_field.value = start_year_field.dataset.evValue
            }

            let end_year_field = document.getElementById('pub-date-filter-end-year')
            if (end_year_field) {
                end_year_field.value = end_year_field.dataset.evValue
            }
        })

        document.addEventListener("turbolinks:load", () => {
                const searchFacetsEl = document.getElementById('search-facets')
                if (searchFacetsEl === null) {
                    return
                }

                Array.from(searchFacetsEl.getElementsByTagName('ul')).forEach(ul => {
                    // wire up "View all" buttons to show all facets
                    ul.querySelector('button.view-all')?.addEventListener('click', (e) => {
                        Array.from(ul.getElementsByTagName('li')).forEach(li => {
                            if (li.dataset.evFacetElidable === 'true') {
                                li.classList.remove('d-none')
                            }
                        })
                        e.target?.closest('li')?.classList.add('d-none')
                        ul.querySelector('button.view-fewer')?.closest('li')?.classList?.remove('d-none')
                    })
                    // wire up "View fewer" buttons to show fewer facets
                    ul.querySelector('button.view-fewer')?.addEventListener('click', (e) => {
                        Array.from(ul.getElementsByTagName('li')).forEach(li => {
                            if (li.dataset.evFacetElidable === 'true') {
                                li.classList.add('d-none')
                            }
                        })
                        e.target.closest('li')?.classList?.add('d-none')
                        ul.querySelector('button.view-all')?.closest('li')?.classList?.remove('d-none')
                    })
                    // wire up expand facet buttons
                    ul.querySelectorAll('.expand-children').forEach(div => {
                        div.addEventListener('click', (e) => {
                            const expandLi = e.target.closest('li')

                            div.classList.add('d-none')
                            expandLi.querySelector('.collapse-children')?.classList?.remove('d-none')
                            ul.querySelectorAll('li[data-ev-facet-qualifier-name]').forEach(li => {
                                if (li.dataset.evFacetQualifierName.length > 0 && li.dataset.evFacetQualifierName === expandLi.dataset.evFacetName) {
                                    li.classList.remove('d-none')
                                }
                            })
                        })
                    })
                    // wire up collapse facet buttons
                    ul.querySelectorAll('.collapse-children').forEach(div => {
                        div.addEventListener('click', (e) => {
                            const collapseLi = e.target.closest('li')

                            div.classList.add('d-none')
                            collapseLi.querySelector('.expand-children')?.classList?.remove('d-none')
                            ul.querySelectorAll('li[data-ev-facet-qualifier-name]').forEach(li => {
                                if (li.dataset.evFacetQualifierName.length > 0
                                    && (li.dataset.evFacetQualifierName === collapseLi.dataset.evFacetName
                                        || li.dataset.evFacetQualifierName.startsWith(`${collapseLi.dataset.evFacetName}/`))) {
                                    li.classList.add('d-none')
                                }
                            })
                        })
                    })
                })

                // wire up filter links <=> filter checkboxes behaviour
                Array.from(searchFacetsEl.querySelectorAll('input[type="checkbox"]')).forEach(checkbox => {
                    const facetLinkSelector = checkbox.dataset.evFacetLink
                    if (typeof (facetLinkSelector) !== 'undefined') {
                        const facetLink = document.querySelector(facetLinkSelector);
                        if (facetLink !== null) {
                            facetLink.addEventListener('click', () => {
                                checkbox.checked = true
                            })
                            checkbox.addEventListener('click', () => {
                                facetLink.click()
                            })
                        }
                    }
                })
            }
        )
    }
}

export default SearchFacets