import "@popperjs/core"
import * as bootstrap from 'bootstrap'

class SearchSelection {

    static start() {
        // clean the DOM state before caching
        document.addEventListener('turbolinks:before-cache', () => {
            if (!document.getElementById('search-results-select-actions')) {
                return
            }

            const search = document.getElementById('search-results-page')?.dataset.evSearch
            if (typeof (search) === 'undefined' || search.length === 0) {
                return
            }

            this.resetSelection()
        })

        // set up listeners
        document.addEventListener("turbolinks:load", () => {
            if (!document.getElementById('search-results-select-actions')) {
                return
            }

            const search = document.getElementById('search-results-page')?.dataset.evSearch
            if (typeof (search) === 'undefined' || search.length === 0) {
                return
            }

            this.resetSelection()

            const selectionForms = [].slice.call(document.querySelectorAll('.search-results-selection-select-form'))
            selectionForms.forEach(form => {
                form.querySelectorAll('select[name = "citations[source]"]').forEach(select => {
                    // Show/hide checkboxes depending on selection
                    select.addEventListener('change', e => {
                        if (e.target.value === 'selection') {
                            this.clearAndShowCheckboxes()
                            form.querySelectorAll('button.search-results-selection-select-form-next-btn').forEach(button => {
                                button.disabled = true
                            })
                        } else {
                            this.resetSelection()
                        }
                    })
                })
            })

            const selectCheckboxes = [].slice.call(document.querySelectorAll('.search-results-select'))
            selectCheckboxes.forEach(
                checkbox => {
                    checkbox.addEventListener('change', _ => {
                        this.setSelection(this.calculateSelection())
                    })
                })
        })

        document.addEventListener("shown.bs.collapse", e => {
            if (e.target.id === 'search-results-select-actions') {
                let savePanelEl = document.getElementById("search-results-select-action-panel-file")
                if (savePanelEl) {
                    let savePanelCollapse = bootstrap.Collapse.getOrCreateInstance(savePanelEl, {
                        toggle: false
                    })
                    if (savePanelCollapse) {
                        savePanelCollapse.show()
                    }
                }
            }
        })

        document.addEventListener("hidden.bs.collapse", e => {
            if (e.target.id === 'search-results-select-actions') {
                let targets = ['file', 'printer', 'email']
                targets.forEach(target => {
                    let panelEl = document.getElementById(`search-results-select-action-panel-${target}`)
                    if (panelEl) {
                        let panelCollapse = bootstrap.Collapse.getOrCreateInstance(panelEl, {
                            toggle: false
                        })
                        if (panelCollapse) {
                            panelCollapse.hide()
                        }
                    }
                })
            } else if (['search-results-select-action-panel-file', 'search-results-select-action-panel-printer', 'search-results-select-action-panel-email'].indexOf(e.target.id) !== -1) {
                this.resetSelection()
            }
        })
    }

    static resetSelection() {
        const selectionForms = [].slice.call(document.querySelectorAll('.search-results-selection-select-form'))

        selectionForms.forEach(form => {
            form.querySelectorAll('input.selected').forEach(input => {
                input.remove()
            })

            form.querySelectorAll('select[name = "citations[source]"]').forEach(select => {
                for (const option of select.options) {
                    if (option.value === 'selection') {
                        option.selected = false
                        option.label = 'Selected Results Only (0)'
                    }
                }
            })
            form.querySelectorAll('button.search-results-selection-select-form-next-btn').forEach(button => {
                button.disabled = false
            })
        })

        this.clearAndHideCheckboxes()
    }

    static clearAndHideCheckboxes() {
        const selectCheckboxes = [].slice.call(document.querySelectorAll('.search-results-select'))

        selectCheckboxes.forEach(checkbox => {
            checkbox.checked = false
            checkbox.closest('td')?.classList.add('visually-hidden')
        })
    }

    static clearAndShowCheckboxes() {
        const selectCheckboxes = [].slice.call(document.querySelectorAll('.search-results-select'))

        selectCheckboxes.forEach(checkbox => {
            checkbox.checked = false
            checkbox.closest('td')?.classList.remove('visually-hidden')
        })
    }

    static calculateSelection() {
        const selectCheckboxes = [].slice.call(document.querySelectorAll('.search-results-select'))

        const selectedOnPage = []
        selectCheckboxes.forEach(checkbox => {
            if (checkbox.checked) {
                selectedOnPage.push(checkbox.dataset.evTarget)
            }
        })
        return selectedOnPage
    }

    static setSelection(selection) {
        const selectionForms = [].slice.call(document.querySelectorAll('.search-results-selection-select-form'))

        selectionForms.forEach(form => {
            form.querySelectorAll('input.selected').forEach(input => {
                input.remove()
            })
            form.querySelectorAll('select[name = "citations[source]"]').forEach(select => {
                for (const option of select.options) {
                    if (option.value === 'selection') {
                        option.label = `Selected Results Only (${selection.length})`
                        break
                    }
                }
            })
            if (selection.length > 0) {
                form.querySelectorAll('button.search-results-selection-select-form-next-btn').forEach(button => {
                    button.disabled = false
                })
            } else {
                form.querySelectorAll('button.search-results-selection-select-form-next-btn').forEach(button => {
                    button.disabled = true
                })
            }
        })
        selectionForms.forEach(form => {
            selection.forEach((articleId, index) => {
                const input = document.createElement('input')
                input.setAttribute('class', 'selected')
                input.setAttribute('type', 'hidden')
                input.setAttribute('name', `citations[article[${index}]]`)
                input.setAttribute('value', articleId)
                form.append(input)
            })
        })
    }


}

export default SearchSelection