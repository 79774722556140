import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"

import "channels"
import "trix"
import "@rails/actiontext"

import AdminNewUpdate from '../js/admin/new-update'
import Alerts from '../js/alerts'
import AutoReload from '../js/auto-reload'
import Badges from '../js/badges'
import Citations from '../js/citations'
import Flash from '../js/flash'
import SearchAdvancedForm from '../js/search-advanced-form'
import SearchFacets from '../js/search-facets'
import SearchHighlights from '../js/search-highlights'
import SearchOrder from '../js/search-order'
import SearchPageSize from '../js/search-page-size'
import SearchResultsCount from '../js/search-results-count'
import SearchSelection from '../js/search-selection'
import SearchSimpleForm from '../js/search-simple-form'
import Tooltips from '../js/tooltips'

require.context('../images', true)

class Evidentia {
    static start() {
        // platform libraries
        Rails.start()
        Turbolinks.start()
        Turbolinks.setProgressBarDelay(250)

        // site-wide modules

        Alerts.start()
        AutoReload.start()
        Flash.start()
        Tooltips.start()

        // page-specific modules

        // admin new update page
        AdminNewUpdate.start()

        // search results, alerts and article pages
        Badges.start()
        Citations.start()

        // search results page
        SearchAdvancedForm.start()
        SearchFacets.start()
        SearchHighlights.start()
        SearchOrder.start()
        SearchPageSize.start()
        SearchResultsCount.start()
        SearchSelection.start()
        SearchSimpleForm.start()
    }
}

export default Evidentia