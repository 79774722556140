import TurbolinksHelper from "./turbolinks-helper";
import * as bootstrap from 'bootstrap'

class Flash {
    static start() {
        document.addEventListener("turbolinks:load", () => {
            var alertList = [].slice.call(document.querySelectorAll('#flash .alert.alert-success'))
            if (alertList.length > 0) {
                var alerts = alertList.map(function (element) {
                    return new bootstrap.Alert(element)
                })

                TurbolinksHelper.setTimeout(function () {
                    alerts.forEach(alert => alert.close())
                }, 5000)
            }
        })
    }
}

export default Flash