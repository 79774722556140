class Citations {
    static start() {
        document.addEventListener("turbolinks:load", () => {
            const citationsForm = document.getElementById('citations-form')
            if (citationsForm) {
                // a change to format or target will re-submit the form in preview mode
                document.getElementById('citations-format')?.addEventListener('change', () => {
                    citationsForm.submit()
                })
                document.getElementById('citations-target')?.addEventListener('change', () => {
                    citationsForm.submit()
                })
                document.getElementById('citations-submit-email')?.addEventListener('click', () => {
                    // only the "Email" button submits the form and may take some time to return so it disables itself - disable
                    // the Cancel "button" as well
                    document.getElementById('citations-cancel')?.classList.add('disabled')
                })
            }
        })
    }
}

export default Citations