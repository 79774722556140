import "@popperjs/core"

class SearchHighlights {
    static start() {
        function setCookie(name, value, days) {
            const date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            document.cookie = `${name}=${value};expires=${date.toUTCString()};path=/;domain=${window.location.hostname}`;
        }

        window.addEventListener('click', function (e) {
            let toggle = document.getElementById('search-highlights-toggle');
            if (toggle !== null && toggle.contains(e.target)) {
                let collapsed = toggle.classList.contains('collapsed')

                setCookie('ev-prefs-search-highlights-collapsed', collapsed, 365)

                let heading = document.getElementById('search-highlights-heading');
                let elidedRow = document.getElementById('search-highlights-elided')
                if (collapsed) {
                    heading?.classList?.add('opacity-50')
                    elidedRow?.classList?.add('show', 'opacity-50')
                } else {
                    heading?.classList?.remove('opacity-50')
                    elidedRow?.classList?.remove('show', 'opacity-50')
                }
            }
        })
    }
}

export default SearchHighlights
