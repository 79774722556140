class SearchResultsCount {
    static start() {
        function roundToMostSignificantDigit(num) {
            // Handle edge case for zero
            if (num === 0) return 0

            // Determine the order of magnitude (power of 10)
            const magnitude = Math.floor(Math.log10(Math.abs(num)))

            // Calculate the divisor based on the magnitude
            const divisor = Math.pow(10, magnitude)

            // Divide the number by the divisor and floor it
            const mostSignificantDigit = Math.floor(num / divisor)

            // Multiply back by the divisor
            return mostSignificantDigit * divisor
        }

        document.addEventListener("turbolinks:load", () => {
            let searchResults = document.getElementById('search-results')
            if (!searchResults) {
                return
            }

            let countKnown = searchResults.dataset.evSearchResultsCountKnown
            if (countKnown === 'false') {
                let countEstimatable = searchResults.dataset.evSearchResultsCountEstimatable
                if (countEstimatable === 'true') {
                    fetch(`/api/v1/articles/count/estimate?t=${new Date().getTime()}&${window.location.search.substring(1)}`)
                        .then(response => {
                            if (!response.ok) {
                                throw new Error('Network response was not ok ' + response.statusText)
                            }
                            return response.json()
                        })
                        .then(data => {
                            if (data.count >= 0) {
                                document.querySelectorAll('span.search-results-count').forEach(span => {
                                    span.textContent = `${roundToMostSignificantDigit(data.count).toLocaleString('en-GB')}+ results`
                                })
                            }
                        })
                        .catch(error => {
                            console.error('Failed to estimate the search count:', error)
                        })
                }
            }
        })
    }
}

export default SearchResultsCount