import Turbolinks from "turbolinks"
import FormHelper from "./form-helper"

class SearchSimpleForm {
    static start() {
        let ids = ['mobile-search-simple-form', 'search-simple-form']
        ids.forEach(formId => {
            document.addEventListener('turbolinks:load', () => {
                let textArea = document.getElementById(`${formId}-text-area`)
                if (!textArea) {
                    return
                }

                let submitButton = document.getElementById(`${formId}-submit-button`);
                if (!submitButton) {
                    return
                }

                textArea.style.height = submitButton.getBoundingClientRect().height + "px"

                textArea.addEventListener("focus", function(e) {
                    let submitButton = document.getElementById(`${formId}-submit-button`);
                    let minHeight = submitButton.getBoundingClientRect().height;

                    // setting height to auto then allows scrollHeight to measure the height with the current text
                    e.target.style.height = 'auto'

                    let newHeight = Math.max(minHeight, e.target.scrollHeight);
                    
                    if (minHeight >= newHeight) {
                        e.target.classList.remove('rounded-bottom')
                    } else {
                        e.target.classList.add('rounded-bottom')
                    }
                    e.target.style.height = newHeight + "px"
                })

                textArea.addEventListener("blur", function(e) {
                    let submitButton = document.getElementById(`${formId}-submit-button`);
                    let newHeight = submitButton.getBoundingClientRect().height;

                    e.target.classList.remove('rounded-bottom')
                    e.target.style.height = newHeight + "px"
                })

                // Auto-adjust the text area size as lines are added and toggle rounded bottom depending on whether
                // it is one line or multiple
                textArea.addEventListener("input", function (e) {
                    let submitButton = document.getElementById(`${formId}-submit-button`);
                    let minHeight = submitButton.getBoundingClientRect().height

                    // setting height to auto then allows scrollHeight to measure the height with the current text
                    e.target.style.height = 'auto'

                    let newHeight = Math.max(minHeight, e.target.scrollHeight)
                    if (minHeight >= newHeight) {
                        e.target.classList.remove('rounded-bottom')
                    } else {
                        e.target.classList.add('rounded-bottom')
                    }

                    e.target.style.height = newHeight + "px"
                }, false)
                
                // Intercept enter and submit the form; shift-enter acts like enter usually does
                textArea.addEventListener("keypress", (e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                        e.preventDefault()

                        if (!e.repeat) {
                            let form = e.target.form;
                            let submitButton = document.getElementById(`${formId}-submit-button`)
                            Turbolinks.visit(form.action + (form.action.indexOf('?') === -1 ? '?' : '&') + FormHelper.serialize(form, submitButton));
                        }
                    }
                })

            })
        })

        window.addEventListener('submit', function (e) {
            if (e.target.id === 'search-simple-form' || e.target.id === 'mobile-search-simple-form') {
                e.preventDefault()
                Turbolinks.visit(e.target.action + (e.target.action.indexOf('?') === -1 ? '?' : '&') + FormHelper.serialize(e.target, e.submitter));
            }
        })

        window.addEventListener('input', function (e) {
            if (e.target.dataset.evTextClearedBy) {
                let clearButton = document.getElementById(e.target.dataset.evTextClearedBy)
                if (clearButton) {
                    let emptyText = e.target.value.trim().length === 0;

                    if (emptyText) {
                        clearButton.classList.add('d-none')
                    } else {
                        // clearButtonX.item(0).classList.remove('invisible')
                        clearButton.classList.remove('d-none')
                    }
                }
            }
        })
    }
}

export default SearchSimpleForm