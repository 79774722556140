class FormHelper {
    static serialize(form, submitter) {
        let field,
            l,
            s = [];

        if (typeof form == 'object' && form.nodeName === "FORM") {
            let len = form.elements.length;

            for (let i = 0; i < len; i++) {
                field = form.elements[i];
                if (field.name && !field.disabled && field.type !== 'button' && field.type !== 'file' && field.type != 'reset' && field.type != 'submit') {
                    if (field.type === 'select-multiple') {
                        l = form.elements[i].options.length;

                        for (let j = 0; j < l; j++) {
                            if (field.options[j].selected) {
                                s[s.length] = encodeURIComponent(field.name) + "=" + encodeURIComponent(field.options[j].value);
                            }
                        }
                    } else if ((field.type !== 'checkbox' && field.type !== 'radio') || field.checked) {
                        s[s.length] = encodeURIComponent(field.name) + "=" + encodeURIComponent(field.value);
                    }
                }
            }
            if (typeof submitter == 'object' && submitter.nodeName === "BUTTON") {
                if (submitter.name && submitter.value) {
                    s[s.length] = encodeURIComponent(submitter.name) + "=" + encodeURIComponent(submitter.value)
                }
            }
        }
        return s.join('&')
    }
}
export default FormHelper